import { useEffect, useRef, useState } from "react";
import One from "../../images/1.webp";
import Two from "../../images/2.webp";
import Three from "../../images/3.webp";
import Four from "../../images/4.webp";
import Five from "../../images/5.webp";
import Six from "../../images/6.webp";
import Icon1 from "../../images/icon1.png";
import Icon2 from "../../images/icon2.png";
import Icon3 from "../../images/icon3.png";
import Icon4 from "../../images/icon4.png";
import Icon5 from "../../images/icon5.png";
import Icon6 from "../../images/icon6.png";
import ServiceItem from "./ServiceItem";
import "./Services.css";

const services = [
  {
    id: 1,
    title: `Brand & Marketing consulting`,
    header: `Beled Solutions offers strategic direction for aligning branding and
    marketing with business strategies, while Tradevale consulting
    services provide comprehensive understanding of compliance
    initiatives and customized assessments to mitigate risks and gain a
    competitive trade advantage.`,
    image: One,
  },
  {
    id: 2,
    title: `Find products, supplier and factories`,
    header: `Beled specializes in connecting clients to reliable suppliers and
    cost-efficient factories in emerging markets, particularly in China,
    providing expert sourcing and logistics solutions to meet business
    needs and ensure customer satisfaction.`,
    image: Two,
  },
  {
    id: 3,
    title: `Quality assurance`,
    header: `Beled dedicated Quality Assurance service helps you meet and
    exceed customer expectations by ensuring your goods and services
    align with stringent export, safety, and quality standards, offering
    robust solutions tailored to your unique needs and challenges.`,
    image: Three,
  },
  {
    id: 4,
    title: `Import / Export worldwide`,
    header: `Beled offers dedicated end-to-end export services globally,
    leveraging their distribution network to provide competitive product
    and service offerings to international markets, while also
    streamlining export processes, enhancing compliance rates, and
    increasing transaction visibility for their clients.`,
    image: Four,
  },
  {
    id: 5,
    title: `Amazon FBA specialist`,
    header: `We elevate businesses and income by providing customers with the
    ability to purchase products through Amazon, increasing listing
    traffic, enhancing impression conversion rates, defending brands,
    sales volume, and content, and developing and growing Amazon sales
    channels with expertise in the ever-changing marketplace.`,
    image: Five,
  },
  {
    id: 6,
    title: `Solve disputes with suppliers`,
    header: `Our experienced negotiators work diligently to address and resolve
    quality defects with your Chinese suppliers, ensuring your business
    remains uninterrupted and thriving while achieving successful
    outcomes where others may have struggled.`,
    image: Six,
  },
];

const Services = () => {
  const contentElement = useRef(null);
  const [active, setActive] = useState(null);

  useEffect(() => {
    setActive(1);
  }, []);

  const handleToggle = (index) => {
    if (active !== index) {
      setActive(index);
    }
  };

  return (
    <section className="services" id="services">
      <h1>Our services</h1>
      <div className="services__container">
        <div className="service__container_left">
          <button
            className={
              active === 1 ? "services__card_active" : "services__card"
            }
            onMouseEnter={() => handleToggle(1)}
            tabIndex={0}
          >
            <img src={Icon1} alt="" />
            <h2>Brand & Marketing consulting</h2>
          </button>

          <div
            ref={contentElement}
            className={`collapse3 ${active === 1 ? "show" : ""}`}
            style={active === 1 ? { height: "200px" } : { height: "0px" }}
          >
            {services.map((service, index) => {
              return (
                <ServiceItem key={index} active={active} service={service} />
              );
            })}
          </div>

          <button
            className={
              active === 2 ? "services__card_active" : "services__card"
            }
            onMouseEnter={() => handleToggle(2)}
            tabIndex={0}
          >
            <img src={Icon2} alt="" />
            <h2>Find products, supplier and factories</h2>
          </button>

          <div
            ref={contentElement}
            className={`collapse3 ${active === 2 ? "show" : ""}`}
            style={active === 2 ? { height: "160px" } : { height: "0px" }}
          >
            {services.map((service, index) => {
              return (
                <ServiceItem key={index} active={active} service={service} />
              );
            })}
          </div>

          <button
            className={
              active === 3 ? "services__card_active" : "services__card"
            }
            onMouseEnter={() => handleToggle(3)}
            tabIndex={0}
          >
            <img src={Icon3} alt="" />
            <h2>Quality assurance</h2>
          </button>

          <div
            ref={contentElement}
            className={`collapse3 ${active === 3 ? "show" : ""}`}
            style={active === 3 ? { height: "200px" } : { height: "0px" }}
          >
            {services.map((service, index) => {
              return (
                <ServiceItem key={index} active={active} service={service} />
              );
            })}
          </div>

          <button
            className={
              active === 4 ? "services__card_active" : "services__card"
            }
            onMouseEnter={() => handleToggle(4)}
            tabIndex={0}
          >
            <img src={Icon4} alt="" />
            <h2>Import / Export worldwide</h2>
          </button>

          <div
            ref={contentElement}
            className={`collapse3 ${active === 4 ? "show" : ""}`}
            style={active === 4 ? { height: "220px" } : { height: "0px" }}
          >
            {services.map((service, index) => {
              return (
                <ServiceItem key={index} active={active} service={service} />
              );
            })}
          </div>

          <button
            className={
              active === 5 ? "services__card_active" : "services__card"
            }
            onMouseEnter={() => handleToggle(5)}
            tabIndex={0}
          >
            <img src={Icon5} alt="" />
            <h2>Amazon FBA specialist</h2>
          </button>

          <div
            ref={contentElement}
            className={`collapse3 ${active === 5 ? "show" : ""}`}
            style={active === 5 ? { height: "240px" } : { height: "0px" }}
          >
            {services.map((service, index) => {
              return (
                <ServiceItem key={index} active={active} service={service} />
              );
            })}
          </div>

          <button
            className={
              active === 6 ? "services__card_active" : "services__card"
            }
            onMouseEnter={() => handleToggle(6)}
            tabIndex={0}
          >
            <img src={Icon6} alt="" />
            <h2>Solve disputes with suppliers</h2>
          </button>

          <div
            ref={contentElement}
            className={`collapse3 ${active === 6 ? "show" : ""}`}
            style={active === 6 ? { height: "160px" } : { height: "0px" }}
          >
            {services.map((service, index) => {
              return (
                <ServiceItem key={index} active={active} service={service} />
              );
            })}
          </div>
        </div>

        <div className="service__container_right">
          {services.map((service, index) => {
            return (
              <ServiceItem key={index} active={active} service={service} />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
