import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import "./App.css";
import About from "./components/About/About";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Navbar from "./components/Navbar/Navbar";
import Popup from "./components/Popup/Popup";
import Process from "./components/Process/Process";
import Quote from "./components/Quote/Quote";
import Services from "./components/Services/Services";

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isNavSticky, setIsNavSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsNavSticky(scrollTop > 90);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handlePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <div className="App">
      <Helmet>
        <title>Beled - Trusted Trading Partner</title>
        <meta
          name="description"
          content="Hassle-free China sourcing with reliable suppliers and competitive prices. Partner with us for seamless trading and exceptional results, global trading shipping"
        />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />

        {/* Google / Search Engine Tags */}
        <meta
          itemprop="name"
          content="Trusted Trading Partner - Beled Solutions"
        />
        <meta
          itemprop="description"
          content="Hassle-free China sourcing with reliable suppliers and competitive prices. Partner with us for seamless trading and exceptional results, global trading shipping"
        />
        <meta itemprop="image" content="./logo512.png" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="beledglobal.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Trusted Trading Partner - Beled Solutions"
        />
        <meta
          property="og:description"
          content="Hassle-free China sourcing with reliable suppliers and competitive prices. Partner with us for seamless trading and exceptional results, global trading shipping"
        />
        <meta property="og:image" content="./logo512.png" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="https://beledglobal.com" />
        <meta name="twitter:creator" content="@Royal.prod" />
        <meta property="og:url" content="http://beledglobal.com" />
        <meta property="og:title" content="Beled" />
        <meta
          property="og:description"
          content="Hassle-free China sourcing with reliable suppliers and competitive prices. Partner with us for seamless trading and exceptional results, global trading shipping"
        />
        <meta
          property="og:image"
          content="http://beledglobal.com/logo512.png"
        />
      </Helmet>

      {isPopupOpen && <Popup handlePopup={handlePopup} />}
      <Navbar isNavSticky={isNavSticky} />
      <Hero handlePopup={handlePopup} />
      <Process />
      <Services />
      <Quote handlePopup={handlePopup} />
      <FAQ />
      <About />
      <Footer handlePopup={handlePopup} />
    </div>
  );
}

export default App;
