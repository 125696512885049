import Background from "../../images/background.jpg";
import "./Hero.css";

const Hero = (props) => {
  const { handlePopup } = props;

  const handleRequest = () => {
    handlePopup();
  };

  return (
    <section className="hero" id="home">
      <img className="hero__background" src={Background} alt="Bacground" />
      <div className="hero-container">
        <div className="hero__content">
          <h1>
            We commit. <br></br> We deliver.
          </h1>
          <p>
            From China to your doorstep, we bridge the gap. <br></br> Your
            trusted trading partner, simplifying sourcing and delivering
            success.
          </p>
          <div className="hero__buttons-container">
            <a href="#contact">
              <button className="hero__button1">Contact Us</button>
            </a>
            <button className="hero__button2" onClick={() => handleRequest()}>
              Request a quote
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
