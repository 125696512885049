import { useState } from "react";
import "./FAQ.css";
import FaqItem from "./FaqItem";

const faqs = [
  {
    id: 1,
    header:
      "Is it possible for you to ship my products even though I am not in China?",
    text: `Yes, we can. We have a worldwide access network. Wherever you are, we can ship it for you.`,
  },
  {
    id: 2,
    header: "What is the trade term of FOB and EXW?",
    text: `The meaning of FOB is FREE ON BOARD. If you are FOB Term, supplier will need to cover
    the shipping cost before cargo leaves China. While EXW means EX WORKS. If you are doing EXW, you need to cover all the shipment cost from your supplier’s place to destination address.`,
  },
  {
    id: 3,
    header: "How do we know the chargeable weight?",
    text: `First of all, the goods will be calculated by the actual weight and the volumetric weight.
    Actual weight of one carton = actual weight of one carton. Total is multiplied by the numbers of carton.
    
     
    
    volumetric weight of one carton=L*W*H/5000,get integral ,use centimeter to calculate(like
    (19.1KGS,get 19.5KGS;20.8KGS,get 21KGS;Total is multiplied by the numbers of carton
    
    
    Second, per above calculation, we can know the actual weight and the volumetric weight which is bigger ;the bigger one will be the chargeable weight
    
     
    
    Lastly, all goods arrive in JMLINES Shipping warehouse,we will remeasure them to get the final chargeable weight, then we will issue INVOICE to you.`,
  },
  {
    id: 4,
    header:
      "How long does it take to quantify the potential value opportunity?",
    text: `From initial brief to quantifying the potential value opportunity, takes between a few days for categories we know well to 2 months for new categories.`,
  },
  {
    id: 5,
    header: "What is product inspection?",
    text: `An inspection is the verification of product quality to pre-identified quality specifications before, during or after the production process.`,
  },
  {
    id: 6,
    header: "What kind of products & services do you source?",
    text: `We can source any product or service from commodity to customized. Check our Category Expertise section to see the range.`,
  },
  {
    id: 7,
    header: "What kind of businesses do you work with?",
    text: `We work with small to multi-national companies that are looking to procure goods and services from emerging markets for export or to support their local operations.`,
  },
  {
    id: 8,
    header: "Do you have preferred relationships with suppliers?",
    text: `We are supplier independent, we never take commissions from suppliers, and we conduct our procurement on an objective and documented basis.`,
  },
  {
    id: 9,
    header: "How long does it take to fully qualify a new supplier?",
    text: `To qualify a new supplier that has been identified as offering potential value, takes between a few weeks for simple off-the-shelf products to several months for complex custom engineered products.`,
  },
];

const FAQ = () => {
  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <section className="faq" id="faq">
      <h1 className="faq__title">Frequently asked questions</h1>

      <div className="faq__container">
        {faqs.map((faq, index) => {
          return (
            <FaqItem
              key={index}
              active={active}
              handleToggle={handleToggle}
              faq={faq}
            />
          );
        })}
      </div>
    </section>
  );
};

export default FAQ;
