import { useRef } from "react";
import "./Services.css";

const ServiceItem = (props) => {
  const contentEl = useRef(null);
  const { active, service } = props;
  const { id, header, image } = service;

  return (
    <div
      ref={contentEl}
      className={`collapse2 ${active === id ? "show" : ""}`}
      style={
        active === id
          ? { height: contentEl.current.scrollHeight }
          : { height: "0px" }
      }
    >
      <div className={active === id ? "text__active" : "text"}>
        <p className="textItem__text">{header}</p>
        <img className="serviceItem__image" src={image} alt={header} />
      </div>
    </div>
  );
};

export default ServiceItem;
