import Lady from "../../images/lady.png";
import "./Quote.css";

const Quote = (props) => {
  const { handlePopup } = props;

  const handleRequest = () => {
    handlePopup();
  };

  return (
    <section className="quote" id="quote">
      <img className="quote__img" src={Lady} alt="lady" />
      <div className="quote__container">
        <div className="quote__content">
          <h1>Beled is your trusted trading partner!</h1>
          <button className="quote__button" onClick={() => handleRequest()}>
            Get your quote today!
          </button>
        </div>
      </div>
    </section>
  );
};

export default Quote;
