import "./About.css";

const About = () => {
  return (
    <section className="about" id="about">
      <h1>About us</h1>
      <p>
        Beled, a reputable trading company in China since 2015, is your reliable
        partner in sourcing specific products and goods efficiently from China
        to your warehouse. With a dedicated team and innovative solutions, we
        ensure a seamless process to meet all your needs.
      </p>
      <h1>Why choosing us?</h1>
      <p>
        Choose Beled for a worry-free China sourcing experience. Our
        professional staff will handle everything from finding the right
        products to securing the best prices and quality, allowing you to focus
        on other important aspects of your business while we ensure a successful
        and reliable partnership.
      </p>
    </section>
  );
};

export default About;
