import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import Email from "../../images/email_icon.png";
import Facebook from "../../images/facebook.png";
import Background from "../../images/footer_background.png";
import Instagram from "../../images/instagram.png";
import LinkedIn from "../../images/linkedin.png";
import Success from "../../images/success_icon.png";
import Phone from "../../images/telephone-call.png";
import Whatsapp from "../../images/whatsapp.png";
import "./Footer.css";

const Footer = () => {
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [done, setDone] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!name || !email || !message) {
      setError(true);
      setErrorMessage("Please fill all the fields!");
      setLoading(false);
      return;
    }

    if (!isValidEmail(email)) {
      setError(true);
      setErrorMessage("Email is invalid!");
      setLoading(false);
      return;
    } else {
      setError(false);
      setLoading(true);

      emailjs
        .sendForm(
          "service_g5az8ue",
          "template_jzy2drx",
          form.current,
          "FPHhzv7XxXyfibgsY"
        )
        .then(
          (result) => {
            console.log(result.text);
            setDone(true);
          },
          (error) => {
            console.log(error.text);
            setDone(false);
            setLoading(false);
            setError(true);
            setErrorMessage("Oops, Somthing went wrong...");
          }
        );
    }
  };

  return (
    <footer className="footer" id="contact">
      <img className="footer__img" src={Background} alt="" />
      <h1 className="footer__title">Contact Us</h1>
      <div className="footer__container">
        <aside className="footer__list-container">
          <p className="footer__text">
            We are looking forward to hearing from you. <br></br> feel free to
            get in touch via the form below, We will get back to you as soon as
            possible.
          </p>
          <div className="footer__list-container1">
            <h2 className="footer__list-title">Beled Social</h2>
            <ul className="footer__list">
              <li className="footer__list-item">
                <a
                  href="https://www.linkedin.com/company/g-m-s-commercial-development"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Linkedin page"
                >
                  <img
                    className="footer__list-image"
                    src={LinkedIn}
                    alt="LinkedIn"
                  />
                </a>
              </li>
              <li className="footer__list-item">
                <a
                  href="https://www.facebook.com/people/GMS-Commercial-Development/100079339905949"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Facebook page"
                >
                  <img
                    className="footer__list-image"
                    src={Facebook}
                    alt="Facebook"
                  />
                </a>
              </li>
              <li className="footer__list-item">
                <a
                  href="https://www.instagram.com/g.m.s_development"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram page"
                >
                  <img
                    className="footer__list-image"
                    src={Instagram}
                    alt="Instagram"
                  />
                </a>
              </li>
              <li className="footer__list-item">
                <a
                  href="https://wa.me/972527721953"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Whatsapp link"
                >
                  <img
                    className="footer__list-image"
                    src={Whatsapp}
                    alt="Whatsapp"
                  />
                </a>
              </li>
              <li className="footer__list-item">
                <a
                  href="tel:+8616675524367"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Phone link"
                >
                  <img className="footer__list-image" src={Phone} alt="Phone" />
                </a>
              </li>
              <li className="footer__list-item">
                <a
                  href="mailto:nn@beledglobal.com"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="E-Mail link"
                >
                  <img className="footer__list-image" src={Email} alt="Email" />
                </a>
              </li>
            </ul>
          </div>
        </aside>
        <form className="footer__form" ref={form} onSubmit={handleSubmit}>
          {done ? (
            <>
              <h3 className="footer__form-success">
                Thank you for contact us! <br /> We will get back to you as soon
                as possible.
              </h3>
              <img className="footer__form-image" src={Success} alt="Success" />
            </>
          ) : (
            <>
              <h2 className="footer__form-title">Send us a message</h2>
              <label for="user_name" className="footer__form-label">
                Name
              </label>
              <input
                id="user_name"
                className="footer__form-input"
                type="text"
                name="user_name"
                onChange={(e) => setName(e.target.value)}
                required
                aria-required="true"
              />
              <label for="user_email" className="footer__form-label">
                Email
              </label>
              <input
                id="user_email"
                className="footer__form-input"
                type="text"
                name="user_email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                aria-required="true"
              />
              <label for="message" className="footer__form-label">
                Message
              </label>
              <textarea
                id="message"
                className="footer__form-input-text"
                type="text"
                name="message"
                spellCheck="true"
                onChange={(e) => setMessage(e.target.value)}
                required
                aria-required="true"
              />
              {error && <p className="footer__form-error">{errorMessage}</p>}
              <button
                type="submit"
                value="Send"
                className={`${
                  loading
                    ? "footer__form-button-loading"
                    : "footer__form-button"
                }`}
              >
                {loading ? "Sending..." : "Submit"}
              </button>
            </>
          )}
        </form>
      </div>
      <a
        className="footer__rights"
        href="https://roeilulavy.github.io/portfolio/"
        target="_blank"
        rel="noreferrer"
        aria-label="Royal.prod home page"
      >
        © 2022 - All Rights Reserved by Royal.Prod
      </a>
    </footer>
  );
};

export default Footer;
