import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import Close from "../../images/close.png";
import Success from "../../images/success_icon.png";
import "./Popup.css";

const Popup = (props) => {
  const { handlePopup } = props;
  const popupForm = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [done, setDone] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!name || !email || !phone || !productName || !description) {
      setError(true);
      setErrorMessage("Please fill all the fields!");
      setLoading(false);
      return;
    } else if (!isValidEmail(email)) {
      setError(true);
      setErrorMessage("Email is invalid!");
      setLoading(false);
      return;
    } else {
      setError(false);
      setLoading(true);

      emailjs
        .sendForm(
          "service_g5az8ue",
          "template_csemjxt",
          popupForm.current,
          "FPHhzv7XxXyfibgsY"
        )
        .then(
          (result) => {
            console.log(result.text);
            setDone(true);
          },
          (error) => {
            console.log(error.text);
            setDone(false);
            setLoading(false);
            setError(true);
            setErrorMessage("Oops, Somthing went wrong...");
          }
        );
    }
  };

  return (
    <div className="popup">
      <form
        className="popup__container"
        ref={popupForm}
        onSubmit={handleSubmit}
      >
        <img
          className="popup__close"
          src={Close}
          alt="Close"
          onClick={handlePopup}
        />
        <h1 className="popup__title">Get your quote</h1>
        {done ? (
          <>
            <h3 className="footer__form-success">
              Thank you for contact us! <br /> We will get back to you as soon
              as possible.
            </h3>
            <img className="footer__form-image" src={Success} alt="Success" />
          </>
        ) : (
          <>
            <input
              className="popup__form-input"
              placeholder="*Name"
              type="text"
              name="user_name"
              onChange={(e) => setName(e.target.value)}
              required
            ></input>
            <input
              className="popup__form-input"
              placeholder="*Email address"
              type="email"
              name="user_email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            ></input>
            <input
              className="popup__form-input"
              placeholder="*Phone number"
              type="phone"
              name="user_phone"
              onChange={(e) => setPhone(e.target.value)}
              required
            ></input>
            <input
              className="popup__form-input"
              placeholder="Name of the product"
              type="text"
              name="user_product"
              onChange={(e) => setProductName(e.target.value)}
            ></input>
            <textarea
              className="popup__form-input-text"
              placeholder="Product description"
              type="text"
              name="user_description"
              spellCheck="true"
              onChange={(e) => setDescription(e.target.value)}
            />
            {error && <p className="popup__form-error">{errorMessage}</p>}
            <button
              type="submit"
              value="Send"
              className={`${
                loading ? "popup__form-button-loading" : "popup__form-button"
              }`}
            >
              {loading ? "Sending..." : "Submit"}
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default Popup;
