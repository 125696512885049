import { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import "./Navbar.css";

const Navbar = (isNavSticky) => {
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const innernav = document.querySelector(".inner-nav");

    if (showNavbar === true) {
      innernav.style.left = "0px";
    } else {
      innernav.style.left = "-800px";
    }
  }, [showNavbar]);

  const handleNavbarClick = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <div className={isNavSticky ? "navbar sticky" : "navbar"}>
      <div
        className={`${showNavbar ? "hamburger1" : "hamburger"}`}
        onClick={handleNavbarClick}
      ></div>
      <button className="logo-container" tabIndex={0}>
        <img className="logo" src={Logo} alt="Logo" />
        <h1>Beled</h1>
      </button>

      <nav className="menu">
        <ul className="inner-nav" onClick={() => setShowNavbar(false)}>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#process">Process</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#quote">Quote</a>
          </li>
          <li>
            <a href="#faq">FAQ</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
        </ul>
        <div className="button">
          <a href="#contact">Contact</a>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
