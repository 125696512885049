import { useRef } from "react";
import Arrow from "../../images/arrow-down.png";
import "./FAQ.css";

const FaqItem = (props) => {
  const contentEl = useRef(null);
  const { handleToggle, active, faq } = props;
  const { header, id, text } = faq;

  return (
    <>
      <header
        className={active === id ? "header__active" : "header"}
        onClick={() => handleToggle(id)}
        tabIndex={0}
      >
        <h2 className="faqItem__title">{header}</h2>
        <img className="faqItem__span" src={Arrow} alt="expend more" />
      </header>
      <div
        ref={contentEl}
        className={`collapse ${active === id ? "show" : ""}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <p className="faqItem__text">{text}</p>
      </div>
    </>
  );
};

export default FaqItem;
