import Img1 from "../../images/iconshipping1_PNG.png";
import Img2 from "../../images/iconshipping2_PNG.png";
import Img3 from "../../images/iconshipping3_PNG.png";
import Img4 from "../../images/iconshipping4_PNG.png";
import Img5 from "../../images/iconshipping5_PNG.png";
import "./Process.css";

const Process = () => {
  return (
    <section className="process" id="process">
      <h1>How it work's</h1>
      <div className="process__container">
        <div className="process__card">
          <img
            className="process__card__img"
            src={Img1}
            alt="Product requirement"
          />
          <h2 className="process__card__text">Product requirement</h2>
        </div>

        <div className="process__card">
          <img className="process__card__img2" src={Img5} alt="Arrow" />
        </div>

        <div className="process__card">
          <img
            className="process__card__img"
            src={Img2}
            alt="Locate and Select products, suppliers and manufactures"
          />
          <h2 className="process__card__text">
            Locate and Select products, suppliers and manufactures
          </h2>
        </div>

        <div className="process__card">
          <img className="process__card__img2" src={Img5} alt="Arrow" />
        </div>

        <div className="process__card">
          <img
            className="process__card__img"
            src={Img3}
            alt="Production & Quality assurance"
          />
          <h2 className="process__card__text">
            Production & Quality assurance
          </h2>
        </div>

        <div className="process__card">
          <img className="process__card__img2" src={Img5} alt="Arrow" />
        </div>

        <div className="process__card">
          <img
            className="process__card__img"
            src={Img4}
            alt="Worldwide logistics"
          />
          <h2 className="process__card__text">Worldwide logistics</h2>
        </div>
      </div>
    </section>
  );
};

export default Process;
